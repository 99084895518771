<template>
    <div>
        <b-modal id="modal-add-hot-sport-match" cancel-variant="outline-secondary" :ok-title="$t('Add')"
            :cancel-title="$t('Close')" ok-variant="success" centered no-enforce-focus modal-class="modal-success"
            :title="$t('Add Sport Team')" @ok="confirmAdd" @hidden="resetModal()">
            <!-- form -->
            <validation-observer ref="addSportTeamModal">
                <b-form method="POST" @submit.prevent="validationForm">
                    <b-form-group label-for="sport_type_id">
                        <label class="mb-1">Sport Type</label>
                        <validation-provider #default="{ errors }" name="sport_type_id" rules="required">
                            <v-select v-model="search.sport_type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="sportTypeOption" :reduce="(val) => val.id" :clearable="false"
                                placeholder="Select sport type" label="name" input-id="sport_type_id" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="home_team_id">
                        <label class="mb-1">Home Team</label>
                        <validation-provider #default="{ errors }" name="home_team_id" rules="required">
                            <v-select v-model="search.home_team_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="sportTeamOption" :reduce="(val) => val.id" :clearable="false"
                                placeholder="Select Home Team" label="name" input-id="home_team_id" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="away_team_id">
                        <label class="mb-1">Away Team</label>
                        <validation-provider #default="{ errors }" name="away_team_id" rules="required">
                            <v-select v-model="search.away_team_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="sportTeamOption" :reduce="(val) => val.id" :clearable="false"
                                placeholder="Select sport type" label="name" input-id="away_team_id" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="league_id">
                        <label class="mb-1">Sport League</label>
                        <validation-provider #default="{ errors }" name="league_id" rules="required">
                            <v-select v-model="search.league_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="sportLeaguesOption" :reduce="(val) => val.id" :clearable="false"
                                placeholder="Select League" label="name" input-id="league_id" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="param_extensions">
                        <label class="mb-1">Param Extension</label>
                        <validation-provider #default="{ errors }" name="param_extensions" rules="required">
                            <b-form-input id="param_extensions" v-model="search.param_extensions" class="form-control"
                                placeholder="Please Param Extension" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="param_extensions">
                        <label class="mb-1">Link live stream</label>
                        <validation-provider #default="{ errors }" name="link_live_stream">
                            <b-form-input id="link_live_stream" v-model="search.link_live_stream" class="form-control" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="time_start">
                        <label class="mb-1">Time Start</label>
                        <validation-provider #default="{ errors }" name="time_start" rules="required">
                            <flat-pickr id="timeStart" :config="formatDate" v-model="search.time_start" class="form-control"
                                placeholder="YYYY-MM-DD hh:mm:ss" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="time_end">
                        <label class="mb-1">Time End</label>
                        <validation-provider #default="{ errors }" name="time_end" rules="required">
                            <flat-pickr id="timeEnd" :config="formatDate" v-model="search.time_end" class="form-control"
                                placeholder="YYYY-MM-DD hh:mm:ss" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="status">
                        <label class="mb-1">Status</label>
                        <validation-provider #default="{ errors }" name="status" rules="required">
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="search.status"
                                :options="statusOption" class="w-100" :reduce="(val) => val.value" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>
  
<script>
import {
    ValidationProvider,
    ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    VBModal,
    BInputGroupAppend,
    BFormTextarea,
    BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import router from "@/router";
import Editor from "@tinymce/tinymce-vue";
import API_KEY from "@/auth/apikey/apikeyeditor";
export default {
    components: {
        BLink,
        BRow,
        BCol,
        BTabs,
        BTab,
        BForm,
        BFormGroup,
        BFormInput,
        BFormFile,
        BButton,
        BInputGroup,
        BInputGroupPrepend,
        BModal,
        BFormTextarea,
        BBadge,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        Editor,
        flatPickr
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    props: {
        sportTypeOption: {
            type: Array,
            default: null,
        },
        sportLeaguesOption: {
            type: Array,
            default: null,
        },
        sportTeamOption: {
            type: Array,
            default: null,
        },
        statusOption: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            API_KEY,
            search: {
                name: "",
                sport_type_id: "",
                league_id: "",
                home_team_id: "",
                away_team_id: "",
                time_start: "",
                time_end: "",
                status: "",
                param_extensions: "",
                link_live_stream: ""
            },
        };
    },
    methods: {
        validationForm() {
            this.$refs.addSportTeamModal.validate().then((success) => {
                if (success) {
                    this.createSportHotMatch(this.search);
                }
            });
        },
        async createSportHotMatch(sportTeamData) {
            await this.$store
                .dispatch("sports/createSportHotMatch", {
                    league_id: sportTeamData.league_id,
                    sport_type_id: sportTeamData.sport_type_id,
                    home_team_id: sportTeamData.home_team_id,
                    away_team_id: sportTeamData.away_team_id,
                    time_start: sportTeamData.time_start,
                    time_end: sportTeamData.time_end,
                    param_extensions: sportTeamData.param_extensions,
                    status: sportTeamData.status,
                    link_live_stream: sportTeamData.link_live_stream
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.code == "00") {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message || "success",
                                    icon: "CheckIcon",
                                    variant: "success",
                                },
                            });
                            this.$emit("refetch-data");
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.$bvModal.hide("modal-add-hot-sport-match");
                                    this.resetModal();
                                }, "500");
                            });
                            this.$emit("refetch-data");
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: "AlertTriangleIcon",
                                    variant: "danger",
                                },
                            });
                        }
                    }
                });
        },
        resetModal() {
            this.search = {
                name: "",
                sport_type_id: "",
                league_id: "",
                home_team_id: "",
                away_team_id: "",
                time_start: "",
                time_end: "",
                status: "",
                param_extensions: ""
            };
        },
        confirmAdd(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.validationForm();
        },
    },
    setup() {
        const formatDate = {
            enableTime: true,
            dateFormat: "Y-m-d H:i:s",
        };
        return {
            formatDate
        };
    },
};
</script>
  