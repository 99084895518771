<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="3" class="mb-2">
                        <label>Sport Type</label>
                        <v-select v-model="search.sport_type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="sportTypeOption" :reduce="(val) => val.id" :clearable="false"
                            placeholder="Select sport type" label="name" input-id="sport_type_id" />
                    </b-col>

                    <b-col cols="12" md="3" class="mb-2">
                        <label>Home Team</label>
                        <v-select v-model="search.home_team_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="sportTeamOption" :reduce="(val) => val.id" :clearable="false"
                            placeholder="Select Home Team" label="name" input-id="home_team_id" />
                    </b-col>

                    <b-col cols="12" md="3" class="mb-2">
                        <label>Away Team</label>
                        <v-select v-model="search.away_team_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="sportTeamOption" :reduce="(val) => val.id" :clearable="false"
                            placeholder="Select sport type" label="name" input-id="away_team_id" />
                    </b-col>

                    <b-col cols="12" md="3" class="mb-2">
                        <label>Sport League</label>
                        <v-select v-model="search.league_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="sportLeaguesOption" :reduce="(val) => val.id" :clearable="false"
                            placeholder="Select League" label="name" input-id="league_id" />
                    </b-col>

                    <b-col cols="12" md="3" class="mb-2">
                        <label>Time Start</label>
                        <flat-pickr id="timeStart" :config="formatDate" v-model="search.time_start" class="form-control"
                            placeholder="YYYY-MM-DD hh:mm:ss" />
                    </b-col>

                    <b-col cols="12" md="3" class="mb-2">
                        <label>Time End</label>
                        <flat-pickr id="timeEnd" :config="formatDate" v-model="search.time_end" class="form-control"
                            placeholder="YYYY-MM-DD hh:mm:ss" />
                    </b-col>

                    <b-col cols="12" md="3" class="mb-2">
                        <label>{{ $t("Status") }}</label>
                        <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="search.status"
                            :options="statusOptions" class="w-100" :reduce="(val) => val.value" />
                    </b-col>

                    <b-col cols="12" md="3" class="d-flex align-items-end justify-content-end">
                        <b-button variant="outline-primary" @click="resetFilter()">
                            Reset
                        </b-button>
                        <b-button variant="primary" class="ml-2" @click="handleSearch()">
                            Search
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <b-card no-body>
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Search -->
                    <b-col cols="12" v-if="$can('create', 'sportmatch')">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-button variant="primary" @click="showAddSportTeamModal()">
                                <span class="text-nowrap">
                                    <feather-icon icon="PlusIcon" /> New
                                </span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-if="loading" class="d-flex justify-content-center mb-3">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
            <div v-else>
                <b-table ref="refSportMatchListTable" class="position-relative table-white-space mb-0 max-height-table"
                    sticky-header head-variant="light" :items="sportMatchList" responsive :fields="tableColumns"
                    primary-key="index" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
                    :sort-desc.sync="isSortDirDesc" busy.sync="true">

                    <div slot="table-busy" class="text-center mt-2">
                        <b-spinner class="align-middle"></b-spinner>
                    </div>
                    <template #cell(index)="data">
                        {{ perPage * (currentPage - 1) + data.index + 1 }}
                    </template>

                    <template #cell(league)="data">
                        <div v-if="data.item.league">
                            {{ data.item.league.name }}
                        </div>
                    </template>

                    <template #cell(sport_type)="data">
                        <div v-if="data.item.sport_type">
                            {{ data.item.sport_type.name }}
                        </div>
                    </template>

                    <template #cell(away_team)="data">
                        <div v-if="data.item.away_team">
                            {{ data.item.away_team.name }}
                        </div>
                    </template>

                    <template #cell(home_team)="data">
                        <div v-if="data.item.home_team">
                            {{ data.item.home_team.name }}
                        </div>
                    </template>

                    <template #cell(logo)="data">
                        <b-link :href="baseUrl + data.item.logo" target="_blank" class="cursor-pointer"
                            v-if="data.item.logo != null">
                            <b-img :src="baseUrl + data.item.logo" fluid class="mr-2 rounded"
                                style="width: 100px; height: 100px;" />
                        </b-link>
                        <b-link v-else> </b-link>
                    </template>

                    <template #cell(created_by)="data">
                        {{ data.item.created_by }}
                    </template>

                    <template #cell(updated_by)="data">
                        {{ data.item.updated_by }}
                    </template>

                    <template #cell(created_at)="data">
                        <div v-if="data.item.created_at !== null">
                            {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
                        </div>
                    </template>

                    <template #cell(updated_at)="data">
                        <div v-if="data.item.updated_at !== null">
                            {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
                        </div>
                    </template>

                    <template #cell(status)="data">
                        <span v-if="data.item.status === 1" class="text-success">
                            Active
                        </span>
                        <span v-if="data.item.status === 2" class="text-warning">
                            Inactive
                        </span>
                    </template>

                    <template #cell(action)="data">
                        <b-button v-if="$can('update', 'sportmatch')" v-b-tooltip.hover.v-info :title="$t('Edit')"
                            variant="primary" class="btn-icon btn-sm mr-50" @click="showEditSportTeamModal(data.item)">
                            <feather-icon icon="EditIcon" />
                        </b-button>
                    </template>

                </b-table>
                <div class="p-2">
                    <b-row>
                        <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
                            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                                {{ dataMeta.of }} entries</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
                            <b-pagination v-model="currentPage" :total-rows="totalSportMatch" :per-page="perPage"
                                first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item"
                                next-class="next-item">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>

        <add-hot-sport-match-modal :sport-type-option.sync="sportTypeOption" :sport-leagues-option.sync="sportLeaguesOption"
            :sport-team-option.sync="sportTeamOption" :status-option.sync="statusOptions" @refetch-data="refetchData" />

        <edit-hot-sport-match-modal :hot-sport-match-detail.sync="hotSportMatchDetail"
            :sport-type-option.sync="sportTypeOption" :sport-leagues-option.sync="sportLeaguesOption"
            :sport-team-option.sync="sportTeamOption" :status-option.sync="statusOptions" @refetch-data="refetchData" />
    </div>
</template>
  
<script>
import {
    BButton,
    BModal,
    VBModal,
    VBTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BImg, BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import sportsStoreModule from "@/views/sports/sportsStoreModule";
import AddHotSportMatchModal from "@/views/sports/AddHotSportMatchModal.vue";
import EditHotSportMatchModal from "@/views/sports/EditHotSportMatchModal.vue";
import flatPickr from "vue-flatpickr-component";

export default {
    components: {
        AddHotSportMatchModal,
        EditHotSportMatchModal,
        BButton,
        BFormGroup,
        BModal,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BTable,
        BTr,
        BTh,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BSpinner,
        vSelect,
        BImg,
        flatPickr
    },
    directives: {
        "b-modal": VBModal,
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            hotSportMatchDetail: null,
        };
    },
    methods: {
        showAddSportTeamModal() {
            this.$bvModal.show("modal-add-hot-sport-match");
        },
        showEditSportTeamModal(datas) {
            this.hotSportMatchDetail = datas;
            this.$bvModal.show("modal-edit-hot-sport-match");
        },
    },
    setup() {
        if (!store.hasModule("sports"))
            store.registerModule("sports", sportsStoreModule);
        onUnmounted(() => {
            if (store.hasModule("sports")) store.unregisterModule("sports");
        });

        const toast = useToast();

        const search = ref({
            name: "",
            sport_type_id: "",
            league_id: "",
            home_team_id: "",
            away_team_id: "",
            time_start: "",
            time_end: "",
            status: "",
            param_extensions: ""
        });
        const formatDate = {
            enableTime: true,
            dateFormat: "Y-m-d H:i:s",
        };
        const sportMatchList = ref([]);
        const sportTypeOption = ref([]);
        const sportLeaguesOption = ref([]);
        const sportTeamOption = ref([]);
        const sportTypeIdFilter = ref(null);
        const nameFilter = ref(null);
        const loading = ref(false);

        const perPage = ref(25);
        const totalSportMatch = ref(0);
        const currentPage = ref(1);
        const perPageOptions = [10, 25, 50, 100];
        const sortBy = ref("id");
        const isSortDirDesc = ref(true);

        const refSportMatchListTable = ref(null);

        const tableColumns = [
            { key: "index", label: "#" },
            { key: "league", label: "League" },
            { key: "sport_type", label: "Sport Type" },
            { key: "home_team", label: "Home Team" },
            { key: "away_team", label: "Away Team" },
            { key: "time_start", label: "Time Start" },
            { key: "time_end", label: "Time End" },
            { key: "param_extensions", label: "Param Extensions" },
            { key: "link_live_stream", label: "Link live stream" },
            { key: "status", label: "Status" },
            "created_by",
            "updated_by",
            "created_at",
            "updated_at",
            { key: "action", label: "Action" },
        ];

        const refetchData = () => {
            getSportMatchList();
        };

        watch([currentPage], () => {
            refetchData();
        });

        const getSportMatchList = (ctx, callback) => {
            loading.value = true;
            store.dispatch("sports/fetchSportHotMatch", {
                league_id: search.value.league_id,
                sport_type_id: search.value.sport_type_id,
                home_team_id: search.value.home_team_id,
                away_team_id: search.value.away_team_id,
                time_start: search.value.time_start,
                time_end: search.value.time_end,
                param_extensions: search.value.param_extensions,
                status: search.value.status,
                pageNumber: currentPage.value,
            }).then((response) => {
                if (response.data.code == "00") {
                    sportMatchList.value = response.data.data.map((item, index) => {
                        return { ...item, index: index }
                    });
                    totalSportMatch.value = response.data.count;
                    loading.value = false;
                } else {
                    loading.value = false;
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                }
            });
        };

        store.dispatch("sports/fetchSportTeam", {}).then((response) => {
            sportTeamOption.value = response.sportTeam;
        });

        store.dispatch("sports/fetchSportType", {}).then((response) => {
            sportTypeOption.value = response.sportType;
        });

        store.dispatch("sports/fetchSportLeagues", {}).then((response) => {
            sportLeaguesOption.value = response.sportLeagues;
        });

        const dataMeta = computed(() => {
            const localItemsCount = refSportMatchListTable.value
                ? refSportMatchListTable.value.localItems.length
                : 0;
            return {
                from:
                    perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
                to: perPage.value * (currentPage.value - 1) + localItemsCount,
                of: totalSportMatch.value,
            };
        });

        const statusOptions = [
            { label: "Active", value: 1 },
            { label: "Inactive", value: 2 },
        ];

        const resolveStatus = (status) => {
            if (status == 1) return { label: "Active", variant: "primary" };
            if (status == 2) return { label: "Inactive", variant: "danger" };
            return { label: "Pending", variant: "secondary" };
        };

        const resetFilter = () => {
            search.value.league_id = '';
            search.value.sport_type_id = '';
            search.value.home_team_id = '';
            search.value.away_team_id = '';
            search.value.time_start = '';
            search.value.time_end = '';
            search.value.param_extensions = '';
            search.value.status = '';
        };

        getSportMatchList();

        const handleSearch = () => {
            getSportMatchList();
        }

        return {
            tableColumns,
            perPage,
            currentPage,
            totalSportMatch,
            dataMeta,
            perPageOptions,
            sortBy,
            isSortDirDesc,
            refSportMatchListTable,

            sportMatchList,
            refetchData,
            nameFilter,
            search,

            resetFilter,
            sportTypeIdFilter,

            resolveStatus,
            statusOptions,

            formatDateTime,
            handleSearch,
            baseUrl,
            sportTypeOption,
            sportLeaguesOption,
            sportTeamOption,
            formatDate,
            loading
        };
    },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
